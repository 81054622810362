// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.footer {
  width: 100%;
  padding: 10px 0; /* Reduced padding for a smaller footer */
  display: flex;
  justify-content: center; /* Center footer content horizontally */
  align-items: center; /* Center footer content vertically */
  background-color: #1a1a1a;
  color: white;
  font-size: 0.8em; /* Smaller font size for an understated look */
  position: fixed; /* Fixed position to keep the footer at the bottom */
  bottom: 0;
  left: 0;
}

.footer-links {
  display: flex;
  gap: 20px; /* Space between links */
}

.footer a {
  color: #646cff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer a:hover {
  color: #535bf2;
}
`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,WAAW;EACX,eAAe,EAAE,yCAAyC;EAC1D,aAAa;EACb,uBAAuB,EAAE,uCAAuC;EAChE,mBAAmB,EAAE,qCAAqC;EAC1D,yBAAyB;EACzB,YAAY;EACZ,gBAAgB,EAAE,8CAA8C;EAChE,eAAe,EAAE,oDAAoD;EACrE,SAAS;EACT,OAAO;AACT;;AAEA;EACE,aAAa;EACb,SAAS,EAAE,wBAAwB;AACrC;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* Footer.css */\n.footer {\n  width: 100%;\n  padding: 10px 0; /* Reduced padding for a smaller footer */\n  display: flex;\n  justify-content: center; /* Center footer content horizontally */\n  align-items: center; /* Center footer content vertically */\n  background-color: #1a1a1a;\n  color: white;\n  font-size: 0.8em; /* Smaller font size for an understated look */\n  position: fixed; /* Fixed position to keep the footer at the bottom */\n  bottom: 0;\n  left: 0;\n}\n\n.footer-links {\n  display: flex;\n  gap: 20px; /* Space between links */\n}\n\n.footer a {\n  color: #646cff;\n  text-decoration: none;\n  transition: color 0.3s;\n}\n\n.footer a:hover {\n  color: #535bf2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
